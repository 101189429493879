import './index.css'
function Home() {
    return (
        <div>
            <div className={"home__description"}>
                <p>колода метафорических карт у вас в приложении</p>
                <p>проводите сессии, сохраняйте результаты</p>
                <a href="/support.html">Обратная связь и поддержка</a>
            </div>
        </div>
    )
}

export default Home
