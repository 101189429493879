import './index.css'
import { registerUser } from 'src/Pages/Registration/api'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const RegistrationPage = () => {
    const [searchParams, _] = useSearchParams()
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<any>(null)

    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState<any>(null)
    const [passwordConfirmation, setPasswordConfirmation] = useState<any>(null)

    const [error, setError] = useState<string | null>(null)
    const [isLoading, setLoading] = useState(false)
    const [isRegistered, setRegistered] = useState(false)
    const code = searchParams.get('c') || 'gesserit_2022'

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setEmailError(null);
        setPasswordError(null);
        setError(null);

        (async () => {
            setLoading(true)
            try {
                const result = await registerUser(email, password, code)
                if (result.success) {
                    setRegistered(true)
                    return
                }

                if (result.errors && result.errors['email']) {
                    setEmailError(<Error error={result.errors['email']} />)
                }

                if (result.errors && result.errors['password']) {
                    setPasswordError(result.errors['password'])
                }

                if (result.errors && result.errors['couponcode']) {
                    setError(result.errors['couponcode'])
                }
            } catch (e) {
                setError("Произошла неизвестная ошибка, попробуйте повторить запрос позже")
            } finally {
                setLoading(false)
            }
        })()
    }

    const comparePasswords = (p: string, pc: string) => {
        if (p != pc) {
            setPasswordError("Пароли не совпадают")
        } else {
            setPasswordError(null)
        }
    }

    if (isRegistered) {
        return <div className="RegistrationPage">
            <div className="success">
                <h1>Регистрация прошла успешно!</h1>
                <p>На вашу почту отправлено подтверждение.</p>
            </div>
        </div>
    }

    return <div className="RegistrationPage">
        <Error error={error} />
        <form onSubmit={submitForm}>
            <input
                className={emailError ? 'error' : ''}
                type={'text'}
                placeholder={'Email'}
                onChange={(e) => {
                    setEmailError(null)
                    setEmail(e.target.value)
                }}
            />
            <Error error={emailError} />

            <input
                className={passwordError ? 'error' : ''}
                type={'password'}
                placeholder={'Пароль'}
                onChange={(e) => {
                    setPasswordError(null)
                    setPassword(e.target.value)
                    comparePasswords(e.target.value, passwordConfirmation)
                }}
            />
            <input
                className={passwordError ? 'error' : ''}
                type={'password'}
                placeholder={'Повторите пароль'}
                onChange={(e) => {
                    setPasswordError(null)
                    setPasswordConfirmation(e.target.value)
                    comparePasswords(password, e.target.value)
                }}
            />
            <Error error={passwordError} />

            <button type={'submit'} disabled={isLoading || !!passwordError || !!emailError}>
                Зарегистрироваться
            </button>
        </form>
    </div>
}

export default RegistrationPage

const Error = ({error}: { error?: string | null }) => {
    return<div className={'error'}>{error}</div>
}
