import logo from "./gesserit_logo.svg";
import './App.css';
import RegistrationPage from 'src/Pages/Registration'
import {
    Route, Routes,
} from 'react-router-dom'
import Home from 'src/Pages/Home'
import Agreement from 'src/Pages/Agreement'
import Support from "./Pages/Support";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div>
              <img src={logo} className="App-logo" alt="Gesserit" />
          </div>
          <div>
              Метафорические карты
          </div>
          <Routes>
                <Route index element={<Home />} />
                <Route path="/registration/pro/" element={<RegistrationPage />} />
                <Route path="/agreement.html" element={<Agreement />} />
                <Route path="/support.html" element={<Support />} />
          </Routes>
      </header>
    </div>
  );
}

export default App;
