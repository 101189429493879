import './index.css'
function Support() {
    return (
        <div>
            <div className={"home__feedback"}>
                <p>Свяжитесь с нами, если у вас есть вопросы или предложения:</p>
                <div className={"home__feedback-form"}>

                <iframe src="https://forms.yandex.ru/cloud/63653cd1f47e7351e2158b09/?iframe=1" frameBorder="0"
                        name="ya-form-63653cd1f47e7351e2158b09" width="550"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Support
