import './index.css'
function Agreement() {
    return (
        <div className={'agreement__content'} >
            <h1>СОГЛАШЕНИЕ</h1>
            <h3>об обработке персональных данных</h3>
            <br />
            <p>Присоединяясь к настоящему Соглашению и оставляя свои данные в приложении «Gesserit» (далее – «Приложение»), оператором которого является команда Gesserit, действующий в соответствии с законодательством Российской Федерации, путем заполнения полей формы «Регистрация» и нажатие кнопки «Отправить» Пользователь услуг и (или) Приложения (далее – «Пользователь»):<ul>
                <li>подтверждает, что указанные им персональные данные принадлежат лично ему;</li>
                <li>признает и подтверждает, что он внимательно и в полном объеме ознакомился с настоящим Соглашением и содержащимися в нем условиями обработки его персональных данных, указываемых им в Приложении;</li>
                <li>дает Оператору согласие на обработку предоставляемых персональных данных для целей регистрации Пользователя в Приложении и иных целей, указанных в Соглашении.</li>
            </ul></p>
            <p>Пользователь дает Оператору свое согласие на обработку его персональных данных, а именно совершение действий, предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»: любых действий (операций) или совокупности действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, ранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных), и подтверждает, что, давая такое согласие, он действует свободно, своей волей и в своих интересах либо в интересах представляемого им на законных основаниях лица.</p>
            <p>Согласие Пользователя на обработку персональных данных является конкретным, информированным и осознанным. Согласие применяется для обработки следующих персональных данных Пользователя:<ul>
                <li>имя;</li>
                <li>адрес электронной почты (e-mail).</li></ul></p>
            <p>Пользователь предоставляет Оператору право осуществлять действия с персональными данными в течение установленных нормативными документами сроков для реализации следующих целей:<ul>
                <li>Идентификация Пользователя;</li>
                <li>Взаимодействие с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся действий Оператора, а также обработка запросов и заявок от Пользователя;</li>
                <li>Отправка Пользователю рекламных материалов и информации о специальных предложениях;</li>
                <li>Обеспечение качественной работы приложения Оператора;</li>
                <li>Обеспечение взаимодействия между Пользователем и Оператором в целях оказания ему услуг;</li>
                <li>Обеспечение исполнения нормативных и ненормативных правовых актов, а также решений, поручений и запросов органов государственной власти и лиц, действующих по поручению или от имени таких органов;</li>
                <li>Анализ интересов Пользователя, проведение его опросов.</li></ul></p>
            <p>Также Пользователь предоставляет право передачи третьим лицам, в целях исполнения обязательств по договору с Пользователем, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа, а также по требованию суда.</p>
            <p>Безопасность персональных данных обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных. Приложение обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
            <p>Отзыв согласия на обработку персональных данных, а также просьба об уточнении данных, их полном или частичном удалении могут быть осуществлены путем направления Пользователем соответствующего заявления в простой письменной форме на адрес электронной почты: help@gesserit.online. Согласие может быть отозвано при условии уведомления не менее чем за 30 дней до предполагаемой даты прекращения обработки данных Оператором. Также Пользователь вправе ознакомиться с перечнем своих персональных данных, обрабатываемых Оператором, направив заявление на вышеуказанный адрес электронной почты.</p>
            <p>Оператор обрабатывает персональные данные до полного исполнения всех обязательств перед Пользователем, а также в течение 5 (пяти) лет после исполнения.</p>
            <p>Настоящее Соглашение действует все время до момента прекращения обработки персональных данных.</p>
            <p>Оператор вправе вносить изменения в настоящее Соглашение. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция всегда находится в Приложении.</p>
        </div>
    )
}

export default Agreement
