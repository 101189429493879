const registrationSourceWeb = 1
export async function registerUser(
    email: string,
    password: string,
    couponCode: string,
): Promise<RegisterUserResponse> {
    const response = await fetch(
        chooseApiUrl() + '/register/',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
                couponCode,
                registrationSource: registrationSourceWeb
            }),
        },
    )

    return response.json()
}

function chooseApiUrl(): string {
    if (window.location.hostname === 'localhost') {
        return 'https://localhost:2402'
    }

    return 'https://api.gesserit.online:2402'
}

export interface RegisterUserResponse {
    success: boolean
    errors ?: {[key: string]: string}
}
